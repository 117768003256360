import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const photosGroup: WizardGroupCollection = {
  'photos': {
    persistenceId: 'photos',
    groupName: `Photos`,
    steps: [
      {
        stepName: 'gallery',
        sidebar: {
          content: 'wiz-upp–photos-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-upp–photos:title',
          },
          fields: [
            {
              name: 'photos',
              type: FormControls.IMAGE,
              icon: 'image',
              multiple: true,
              accepts: ['image/jpg', 'image/jpeg', 'image/png'],
              helpText: 'File types supported: JPG, JPEG, PNG',
              collection: 'residentPhotos',
              maxFileCount: 30,
            },
          ],
          actions: {
            custom: {
              label: 'Save & Finish',
              url: '/lifestory'
            }
          }
        },
        footer: {
          component: () => import('../components/image-grid/image-grid.component').then(m => m.WizardImageGridComponent),
          inputs: {
            uploadField: 'photos',
            maxFileCount: 30,
          }
        }
      },
    ]
  }
};